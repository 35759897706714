import { render, staticRenderFns } from "./ArticleList.vue?vue&type=template&id=26b6370e&"
import script from "./ArticleList.vue?vue&type=script&lang=ts&"
export * from "./ArticleList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlockArticleLinksList: require('/home/deploy/scenkonst-om-frontend-staging/releases/20241030141322/src/components/block/ArticleLinksList.vue').default})
